/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Checkbox, Container, FormControlLabel, FormHelperText, IconButton, Switch, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import SaveIcon from "@mui/icons-material/Save";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { actionsStyle, GridContainer, headerStyle, mobileHeaderStyle, rowStyle, labelSpanStyle, textStyle, lineStyle, selectStyle, errorMuiFormHelperText, inputProps } from "./styles";
import { useGetEventsQuery } from "../../services/janApi";
import Yup from "../CustomYupValidation";

const ConfigurationsPanelEvents = (props) => {
    const { t } = useTranslation();
    const { initialConfiguration, onChange, initEvent, getCurrentDimensions, currentAdmin, onError, labels, filterLabelId } = props;
    const { data } = useGetEventsQuery();
    const [confirmDeleteId, setConfirmDeleteId] = useState("");
    const [editableRowId, setEditableRowId] = useState("");
    const [ga3EditMode, setGa3EditMode] = useState(false);
    const [ga4EditMode, setGa4EditMode] = useState(false);
    const [cTpEditMode, setCtpEditMode] = useState(false);
    const [isError, setError] = useState(false);
    const validationSchema = Yup.object().shape({
        events: Yup.array().of(
            Yup.object().shape({
                isActive: Yup.boolean().required(),
                id: Yup.number()
                    .integer()
                    .min(1, t("configuration.errors.min", { attribute: "id", min: 0 }))
                    .required(t("configuration.errors.required", { attribute: "id" }))
                    .uniqueEventId(t("events.form.eventNameIsUsed")),
                ga3: Yup.object().shape({
                    type: Yup.string().required(t("configuration.errors.required", { attribute: "Type" })),
                    category: Yup.string().when("type", {
                        is: (company) => company === "event",
                        then: Yup.string()
                            .required(t("configuration.errors.required", { attribute: "Category" }))
                            .validJs(),
                        otherwise: Yup.string().nullable(),
                    }),
                    action: Yup.string().when("type", {
                        is: (company) => company === "event",
                        then: Yup.string()
                            .required(t("configuration.errors.required", { attribute: "Action" }))
                            .validJs(),
                        otherwise: Yup.string().nullable(),
                    }),
                    label: Yup.string().nullable().validJs(),
                    value: Yup.string().nullable(),
                    isActive: Yup.boolean().required(),
                    nonInteraction: Yup.boolean().required(),
                    c_dimensions: Yup.mixed().validDimension(getCurrentDimensions().list),
                }),
                ga4: Yup.object().shape({
                    type: Yup.mixed().ifAdminActive(currentAdmin.ga4.active, t("configuration.errors.required", { attribute: "Type" })),
                    category: Yup.string().nullable().validJs(),
                    subcategory: Yup.string().nullable().validJs(),
                    isActive: Yup.boolean().required(),
                    nonInteraction: Yup.boolean().required(),
                    c_dimensions: Yup.mixed().validDimension(getCurrentDimensions().list),
                }),
                customTrackingPixel: Yup.object().shape({
                    name: Yup.mixed().ifAdminActive(currentAdmin.customTrackingPixel.active, t("configuration.errors.required", { attribute: "Name" })),
                    isActive: Yup.boolean().required(),
                    c_dimensions: Yup.mixed().validDimension(getCurrentDimensions().list),
                }),
            })
        ),
    });

    const { register, watch, formState, control, reset, setValue, getValues, trigger } = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            events: initialConfiguration.events,
        },
    }); // initialise the hook
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "events",
    });

    function cancel(eventIndex) {
        trigger("events");
        const events = getValues("events");
        const currentEvent = events[eventIndex];
        currentEvent.ga3.c_dimensions = "";
        currentEvent.ga4.c_dimensions = "";
        setConfirmDeleteId(null);
        setEditableRowId(null);
    }

    const onSave = (eventIndex) => {
        trigger("events");
        const events = getValues("events");
        const currentEvent = events[eventIndex];
        currentEvent.ga3.c_dimensions = "";
        currentEvent.ga4.c_dimensions = "";
        onChange(events);
        setEditableRowId(null);
    };

    const onDelete = (eventIndex) => {
        remove(eventIndex);
        onChange(getValues("events"));
        setConfirmDeleteId(null);
    };

    const onAdd = () => {
        append(initEvent);
        setEditableRowId(getValues("events").length);
    };

    const onSelectAllDimensions = (eventIndex) => {
        const events = getValues("events");
        setValue(`events.${eventIndex}.ga4.dimensions`, getCurrentDimensions().ids);
        onChange(events);
    };

    useEffect(() => {
        setEditableRowId("");
        reset({ events: initialConfiguration.events });
        trigger("events");
    }, [initialConfiguration, currentAdmin.ga4.active, currentAdmin.customTrackingPixel.active]);

    useEffect(() => {
        if (Object.keys(errors).length > 0 && isError === false) {
            setError(true);
            onError("events", true);
        }
        if (Object.keys(errors).length === 0 && isError === true) {
            setError(false);
            onError("events", false);
        }
    }, [formState]);

    return (
        <div>
            {initialConfiguration && data && (
                <form>
                    <div>
                        <Box sx={GridContainer} display={{ xs: "none", md: "block" }}>
                            <Grid container sx={headerStyle} direction="row">
                                <Grid className="header-name" key="hk1" item xs={12} md={1.25}>
                                    {t("configuration.events.title")}
                                </Grid>
                                <Grid className="header-name" key="hk6" item xs={12} md={1.25}>
                                    {t("configuration.label")}
                                </Grid>
                                <Grid className="header-ga3" key="hk2" item xs={12} md={2.5}>
                                    {t("configuration.ga3.title")}
                                </Grid>
                                <Grid className="header-ga4" key="hk3" item xs={12} md={2.5}>
                                    {t("configuration.ga4.title")}
                                </Grid>
                                <Grid className="header-customTrackingPixel" key="hk4" item xs={12} md={2.5}>
                                    {t("configuration.customTrackingPixel.title")}
                                </Grid>
                                <Grid className="header-actions" key="header_key_5" item xs={12} md={0.5}>
                                    {t("configuration.actions")}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={GridContainer}>
                            {fields.map((cEvent, index) => {
                                const key = `key-${index}`;

                                function fieldName(field) {
                                    return `events.${index}.${field}`;
                                }

                                function errorField(field, parentField) {
                                    return parentField ? errors?.events?.[index]?.[parentField]?.[field]?.message : errors?.events?.[index]?.[field]?.message;
                                }

                                function label(name, type) {
                                    const tName = t(`common.${name}`);
                                    return `${tName} (${type})`;
                                }

                                const getEventById = (id) => {
                                    if (data !== undefined && data.length > 0) {
                                        return data.find((e) => e.id === id);
                                    }
                                    return "";
                                };
                                const event = getEventById(watch(fieldName("id")));
                                const eventName = event ? event.name : t("configuration.events.deleted");
                                const eventLabelId = event ? event.label_id : null;

                                const labelObject = labels.find((label) => label.id === eventLabelId);
                                const labelName = labelObject ? labelObject.name : "";

                                const getDimensionNameById = (dimensionId) => {
                                    let dimensionName = "not_found_dimension_name";
                                    if (getCurrentDimensions().list !== undefined && getCurrentDimensions().list.length > 0) {
                                        const searchDimension = getCurrentDimensions().list.filter((d) => d.uuid === dimensionId);
                                        if (searchDimension.length > 0) {
                                            dimensionName = searchDimension[0].name;
                                        }
                                    }
                                    return dimensionName;
                                };
                                const getDimensionIdByName = (name) => {
                                    let dimensionId = null;
                                    if (getCurrentDimensions().list !== undefined && getCurrentDimensions().list.length > 0) {
                                        const searchDimension = getCurrentDimensions().list.filter((d) => String(d.name) === String(name));
                                        if (searchDimension.length > 0) {
                                            dimensionId = searchDimension[0].uuid;
                                        }
                                    }
                                    return dimensionId;
                                };
                                const getGa4NameDimensions = () =>
                                    getValues(fieldName("ga4"))
                                        ?.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                        .join(", ");
                                const getGa3NameDimensions = () =>
                                    getValues(fieldName("ga3"))
                                        ?.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                        .join(", ");
                                const getCtpNameDimensions = () =>
                                    getValues(fieldName("customTrackingPixel"))
                                        ?.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                        .join(", ");
                                const switchGa4EditMode = () => {
                                    if (!ga4EditMode) {
                                        const dimensionsName = getValues("events")
                                            [index].ga4.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                            .join(", ");
                                        setValue(fieldName("ga4.c_dimensions"), dimensionsName);
                                    } else {
                                        const names = getValues("events")[index].ga4.c_dimensions.split(", ");
                                        const ids = names.map((name) => getDimensionIdByName(name)).filter((e) => e !== null);
                                        setValue(fieldName("ga4.dimensions"), ids);
                                    }
                                    setGa4EditMode(!ga4EditMode);
                                };
                                const switchGa3EditMode = () => {
                                    if (!ga3EditMode) {
                                        const dimensionsName = getValues("events")
                                            [index].ga3.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                            .join(", ");
                                        setValue(fieldName("ga3.c_dimensions"), dimensionsName);
                                    } else {
                                        const names = getValues("events")[index].ga3.c_dimensions.split(", ");
                                        const ids = names.map((name) => getDimensionIdByName(name)).filter((e) => e !== null);
                                        setValue(fieldName("ga3.dimensions"), ids);
                                    }
                                    setGa3EditMode(!ga3EditMode);
                                };
                                const switchCtpEditMode = () => {
                                    if (!cTpEditMode) {
                                        const dimensionsName = getValues("events")
                                            [index].customTrackingPixel.dimensions.map((dimensionId) => getDimensionNameById(dimensionId))
                                            .join(", ");
                                        setValue(fieldName("customTrackingPixel.c_dimensions"), dimensionsName);
                                    } else {
                                        const names = getValues("events")[index].customTrackingPixel.c_dimensions.split(", ");
                                        const ids = names.map((name) => getDimensionIdByName(name)).filter((e) => e !== null);
                                        setValue(fieldName("customTrackingPixel.dimensions"), ids);
                                    }
                                    setCtpEditMode(!cTpEditMode);
                                };
                                const inlineError = errors.events && errors.events[index] !== undefined;
                                const hide = filterLabelId > 0 && eventLabelId !== filterLabelId ? "hide" : "";
                                const odd = index % 2 === 0 ? "even" : "odd";
                                const bError = inlineError ? "bError" : "";

                                return (
                                    <React.Fragment key={key}>
                                        <Grid container sx={rowStyle} className={`dimension ${odd}  ${bError} ${hide}`}>
                                            <Grid item xs={12} md={1.25}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configurations.ga3.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("configuration.events.title")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{eventName}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.isActive")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("isActive")) === true ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <FormControl fullWidth sx={textStyle}>
                                                            <InputLabel id={`events.${index}.event_label`}>Event</InputLabel>
                                                            <Controller
                                                                name={fieldName("id")}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select {...field} label="Event" labelId={`events.${index}.event_label`} error={Boolean(errorField("id"))} helpertext={errorField("id") ? errorField("id") : ""}>
                                                                        {data !== undefined &&
                                                                            data.map((event) => (
                                                                                <MenuItem key={event.id} value={event.id}>
                                                                                    {event.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                            <FormHelperText sx={errorMuiFormHelperText}>{errorField("id")}</FormHelperText>
                                                        </FormControl>

                                                        <FormControlLabel control={<Checkbox {...register(fieldName("isActive"))} defaultChecked={getValues(fieldName("isActive")) === true} />} label={t("common.isActive")} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={1.25}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.label")}
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container>
                                                        <Grid sx={labelSpanStyle}>{t("configuration.label")} </Grid>
                                                        <Grid sx={{ fontSize: "12px" }}>{labelName}</Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.ga3.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.type")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3"))?.type}</Grid>
                                                        </Grid>
                                                        {getValues(fieldName("ga3.type")) === "event" && (
                                                            <>
                                                                <Grid container>
                                                                    <Grid sx={labelSpanStyle}>{t("common.category")}</Grid>
                                                                    <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3.category"))}</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid sx={labelSpanStyle}>{t("common.action")}</Grid>
                                                                    <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3.action"))}</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid sx={labelSpanStyle}>{t("common.label")}</Grid>
                                                                    <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3.label"))}</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid sx={labelSpanStyle}>{t("common.value")}</Grid>
                                                                    <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3.value"))}</Grid>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.isActive")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3"))?.isActive ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.nonInteraction")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3"))?.nonInteraction ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("configuration.dimensions.title")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga3"))?.dimensions.map((dimensionId) => `, ${getDimensionNameById(dimensionId)} `)}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <FormControl fullWidth sx={selectStyle}>
                                                            <InputLabel id="event-label">Event</InputLabel>
                                                            <Controller
                                                                name={fieldName("ga3.type")}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select {...field} label="Site" labelId="event-label" error={Boolean(errorField("type", "ga3"))} helpertext={errorField("type", "ga3") ? errorField("type", "ga3") : ""}>
                                                                        {["event", "pageview"].map((type) => (
                                                                            <MenuItem key={type} value={type}>
                                                                                {type}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                        {watch("events")[index].ga3.type === "event" && (
                                                            <>
                                                                <TextField
                                                                    sx={textStyle}
                                                                    {...register(fieldName("ga3.category"))}
                                                                    label={label("category", "ga3")}
                                                                    error={Boolean(errorField("category", "ga3"))}
                                                                    helperText={errorField("category", "ga3") ? errorField("category", "ga3") : ""}
                                                                />
                                                                <TextField
                                                                    sx={textStyle}
                                                                    label={label("action", "ga3")}
                                                                    {...register(fieldName("ga3.action"))}
                                                                    error={Boolean(errorField("action", "ga3"))}
                                                                    helperText={errorField("action", "ga3") ? errorField("action", "ga3") : ""}
                                                                />
                                                                <TextField
                                                                    sx={textStyle}
                                                                    label={label("label", "ga3")}
                                                                    {...register(fieldName("ga3.label"))}
                                                                    error={Boolean(errorField("label", "ga3"))}
                                                                    helperText={errorField("label", "ga3") ? errorField("label", "ga3") : ""}
                                                                />
                                                                <TextField
                                                                    sx={textStyle}
                                                                    label={label("value", "ga3")}
                                                                    {...register(fieldName("ga3.value"))}
                                                                    error={Boolean(errorField("value", "ga3"))}
                                                                    helperText={errorField("value", "ga3") ? errorField("value", "ga3") : ""}
                                                                />
                                                            </>
                                                        )}

                                                        <FormControlLabel
                                                            control={<Checkbox {...register(fieldName("ga3.nonInteraction"))} defaultChecked={getValues(fieldName("ga3"))?.nonInteraction === true} />}
                                                            label={t("common.nonInteraction")}
                                                        />
                                                        <FormControlLabel control={<Checkbox {...register(fieldName("ga3.isActive"))} defaultChecked={getValues(fieldName("ga3"))?.isActive === true} />} label={t("common.isActive")} />
                                                        <FormControlLabel control={<Switch checked={ga3EditMode} onChange={switchGa3EditMode} inputProps={{ "aria-label": "controlled" }} />} label={t("configuration.activateEditMode")} />
                                                        {!ga3EditMode && (
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Autocomplete
                                                                            multiple
                                                                            value={value}
                                                                            onChange={(event, value) => setValue(fieldName("ga3.dimensions"), value)}
                                                                            options={getCurrentDimensions().ids}
                                                                            getOptionLabel={(dimensionId) => getDimensionNameById(dimensionId)}
                                                                            renderInput={(params /* eslint-disable-next-line */) => (
                                                                                <TextField {...params} variant="standard" label={t("configuration.dimensions.title")} placeholder={t("configuration.dimensions.title")} />
                                                                            )}
                                                                        />
                                                                    )}
                                                                    control={control}
                                                                    defaultValue={getValues(fieldName("ga3"))?.dimensions}
                                                                    name={fieldName("ga3.dimensions")}
                                                                />
                                                            </FormControl>
                                                        )}
                                                        {ga3EditMode && (
                                                            <TextField
                                                                placeholder={t("configuration.dimensions.title")}
                                                                label={t("configuration.dimensions.title")}
                                                                {...register(fieldName("ga3.c_dimensions"))}
                                                                defaultValue={getGa3NameDimensions()}
                                                                error={Boolean(errorField("c_dimensions", "ga3"))}
                                                                helperText={errorField("c_dimensions", "ga3")}
                                                                multiline
                                                                InputProps={inputProps}
                                                            />
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configurations.ga4.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.type")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.type}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.category")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.category}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.subcategory")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.subcategory}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.isActive")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.isActive ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.nonInteraction")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.nonInteraction ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("configuration.dimensions.title")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("ga4"))?.dimensions.map((dimensionId) => `, ${getDimensionNameById(dimensionId)} `)}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField
                                                            sx={textStyle}
                                                            label={label("type", "ga4")}
                                                            {...register(fieldName("ga4.type"))}
                                                            error={Boolean(errorField("type", "ga4"))}
                                                            helperText={errorField("type", "ga4") ? errorField("type", "ga4") : ""}
                                                        />
                                                        <TextField
                                                            sx={textStyle}
                                                            {...register(fieldName("ga4.category"))}
                                                            label={label("category", "ga4")}
                                                            error={Boolean(errorField("category", "ga4"))}
                                                            helperText={errorField("category", "ga4") ? errorField("category", "ga4") : ""}
                                                        />
                                                        <TextField
                                                            sx={textStyle}
                                                            {...register(fieldName("ga4.subcategory"))}
                                                            label={label("subcategory", "ga4")}
                                                            error={Boolean(errorField("subcategory", "ga4"))}
                                                            helperText={errorField("subcategory", "ga4") ? errorField("subcategory", "ga4") : ""}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox {...register(fieldName("ga4.nonInteraction"))} defaultChecked={getValues(fieldName("ga4"))?.nonInteraction === true} />}
                                                            label={t("common.nonInteraction")}
                                                        />
                                                        <FormControlLabel control={<Checkbox {...register(fieldName("ga4.isActive"))} defaultChecked={getValues(fieldName("ga4"))?.isActive === true} />} label={t("common.isActive")} />
                                                        <FormControlLabel control={<Switch checked={ga4EditMode} onChange={switchGa4EditMode} inputProps={{ "aria-label": "controlled" }} />} label={t("configuration.activateEditMode")} />
                                                        {!ga4EditMode && (
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Autocomplete
                                                                            multiple
                                                                            value={value}
                                                                            onChange={(event, value) => setValue(fieldName("ga4.dimensions"), value)}
                                                                            options={getCurrentDimensions().ids}
                                                                            getOptionLabel={(dimensionId) => getDimensionNameById(dimensionId)}
                                                                            renderInput={(params /* eslint-disable-next-line */) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="standard"
                                                                                    label={t("configuration.dimensions.title")}
                                                                                    placeholder={t("configuration.dimensions.title")}
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        endAdornment: (
                                                                                            <>
                                                                                                <InputAdornment position="end" sx={{ position: "absolute", right: "15%" }}>
                                                                                                    <Tooltip title={t("common.selectAll")}>
                                                                                                        <IconButton color="primary" onClick={() => onSelectAllDimensions(index)}>
                                                                                                            <SelectAllIcon />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </InputAdornment>
                                                                                                {params.InputProps.endAdornment}
                                                                                            </>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                    control={control}
                                                                    defaultValue={getValues(fieldName("ga4"))?.dimensions}
                                                                    name={fieldName("ga4.dimensions")}
                                                                />
                                                            </FormControl>
                                                        )}
                                                        {ga4EditMode && (
                                                            <TextField
                                                                placeholder={t("configuration.dimensions.title")}
                                                                label={t("configuration.dimensions.title")}
                                                                {...register(fieldName("ga4.c_dimensions"))}
                                                                defaultValue={getGa4NameDimensions()}
                                                                error={Boolean(errorField("c_dimensions", "ga4"))}
                                                                helperText={errorField("c_dimensions", "ga4")}
                                                                multiline
                                                                InputProps={inputProps}
                                                            />
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configurations.customTrackingPixel")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.name")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("customTrackingPixel"))?.name}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.isActive")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("customTrackingPixel"))?.isActive ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("configuration.dimensions.title")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{getValues(fieldName("customTrackingPixel"))?.dimensions?.map((dimensionId) => `, ${getDimensionNameById(dimensionId)} `)}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField
                                                            sx={textStyle}
                                                            label={label("name", "customTrackingPixel")}
                                                            {...register(fieldName("customTrackingPixel.name"))}
                                                            error={Boolean(errorField("name", "customTrackingPixel"))}
                                                            helperText={errorField("name", "customTrackingPixel") ? errorField("name", "customTrackingPixel") : ""}
                                                        />

                                                        <FormControlLabel
                                                            sx={{ width: "100%" }}
                                                            control={<Checkbox {...register(fieldName("customTrackingPixel.isActive"))} defaultChecked={getValues(fieldName("customTrackingPixel"))?.isActive === true} />}
                                                            label={t("common.isActive")}
                                                        />
                                                        <FormControlLabel control={<Switch checked={cTpEditMode} onChange={switchCtpEditMode} inputProps={{ "aria-label": "controlled" }} />} label={t("configuration.activateEditMode")} />
                                                        {!cTpEditMode && (
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Autocomplete
                                                                            multiple
                                                                            value={value}
                                                                            onChange={(event, value) => setValue(fieldName("customTrackingPixel.dimensions"), value)}
                                                                            options={getCurrentDimensions().ids}
                                                                            getOptionLabel={(dimensionId) => getDimensionNameById(dimensionId)}
                                                                            renderInput={(params /* eslint-disable-next-line */) => (
                                                                                <TextField {...params} variant="standard" label={t("configuration.dimensions.title")} placeholder={t("configuration.dimensions.title")} />
                                                                            )}
                                                                        />
                                                                    )}
                                                                    control={control}
                                                                    defaultValue={getValues(fieldName("customTrackingPixel"))?.dimensions}
                                                                    name={fieldName("customTrackingPixel.dimensions")}
                                                                />
                                                            </FormControl>
                                                        )}
                                                        {cTpEditMode && (
                                                            <TextField
                                                                placeholder={t("configuration.dimensions.title")}
                                                                label={t("configuration.dimensions.title")}
                                                                {...register(fieldName("customTrackingPixel.c_dimensions"))}
                                                                defaultValue={getCtpNameDimensions()}
                                                                error={Boolean(errorField("c_dimensions", "customTrackingPixel"))}
                                                                helperText={errorField("c_dimensions", "customTrackingPixel")}
                                                                multiline
                                                                InputProps={inputProps}
                                                            />
                                                        )}
                                                    </Grid>
                                                )}
                                                <Grid sx={lineStyle} display={{ xs: "block", md: "none" }}>
                                                    {" "}
                                                </Grid>
                                            </Grid>

                                            <Grid sx={actionsStyle} key="actions_key_btn" item xs={12} md={0.5}>
                                                <ButtonGroup size="small" aria-label="small button group">
                                                    {index !== confirmDeleteId && editableRowId == null && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(index)}>
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== index && confirmDeleteId !== index && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </ButtonGroup>

                                                <ButtonGroup size="small" aria-label="small button group">
                                                    {(confirmDeleteId === index || editableRowId === index) && (
                                                        <IconButton color="error" onClick={() => cancel(index)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === index && (
                                                        <IconButton color="success" onClick={() => onDelete(index)}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === index && (
                                                        <IconButton color="primary" onClick={() => onSave(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Box>

                        <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                            <IconButton color="primary" onClick={() => onAdd()}>
                                <AddCircleOutlineIcon
                                    sx={{
                                        width: 64,
                                        height: 64,
                                        padding: 0,
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ConfigurationsPanelEvents;
